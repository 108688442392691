import { toastMsgError } from "../helpers/toastMessage";
import { saveFileToServer } from "../helpers/utils";

export const STATUS = {
  1: { class: "badge-success", text: "Active" },
  2: { class: "badge-warning", text: "Pause" },
  3: { class: "badge-danger", text: "Block" },
};

export const REGEXP = {
  PHONE: /^(([+]|[0-9])([0-9]+))$/,
  // PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)[^\s]$/,
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)[^\s]{8,128}$/,
  STRONG_PASSWORD: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,128}$/,
  ADMIN_USER_PASSWORD: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d\W]{8,40}$/,
  // eslint-disable-next-line no-useless-escape
  SPECIAL_CHARACTER: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
  NUMBER: /^[0-9]+$/,
  NORMAL_CHARACTER: /.*?[a-zA-Z].*?/,
};

export const FILE_TYPE_ACCEPTED = {
  IMAGE: ".png, .jpg, .jpeg, .webp",
};

export const MODE_MULTISELECT = {
  SINGLE: "single",
  MULTIPLE: "multiple",
  TAGS: "tags",
};

export const FILE_SIZE = {
  "5MB": 5 * 1024 * 1024,
};

export const FILE_TYPE_VALIDATE = {
  IMAGE: ["image/jpeg", "image/jpg", "image/png", "image/webp"],
};

export const DEFAULT_QUERY_DATA = {
  current_page: 1,
  data: [],
  has_more_pages: false,
  last_page: 1,
  per_page: 10,
  total: 0,
};

export const PAGINATION_DEFAULT = {
  LIMIT: 10,
  PAGE: 1,
};

export const EMAIL_KEY = "email";
export const CONTEXT_NAME = {
  AUTH: "auth",
};

export const UPLOAD_TYPE = {
  NOTIFICATION_EDITOR_IMAGE: 1,
  NEWS_EDITOR_IMAGE: 2,
  NOTIFICATION_IMAGE: 3,
  NEWS_IMAGE: 4,
  USER_IMAGE: 5,
  DONATE_EVENT_IMAGE: 6,
  DONATE_EVENT_EDITOR_IMAGE: 7,
};

export const getEditorOptions = (uploadType) => ({
  modules: {
    imageUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          // file type is only image.
          if (/^image\//.test((file as any)?.type)) {
            const uploadImage = async () => {
              const res = await saveFileToServer(file, uploadType);
              if (res.file_url) resolve(res.file_url);
              else {
                toastMsgError("アップロードに失敗しました");
                reject("アップロードに失敗しました");
              }
            };
            uploadImage();
          } else {
            toastMsgError("画像のみアップロード可能でした。");
            reject("画像のみアップロード可能でした。");
          }
        });
      },
    },
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "super" }, { script: "sub" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ header: "1" }, { header: "2" }],
      [{ direction: "rtl" }],

      [{ font: [] }, { size: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      [{ align: [] }],

      ["link", "image", "video", "formula"],
      ["clean"],
    ],
  },

  theme: "snow",
});
